@import "uikit/src/scss/variables-theme.scss";
@import "uikit/src/scss/mixins-theme.scss";

@import "uikit/src/scss/uikit-theme.scss";

.alawaysTop {
  z-index: 2;
}

.navbar {
  z-index: 2;
  position: fixed;
  top: 0px;
}